<template>
  <div>
    <titulo @cadastrar="openModal" />

    <filtro @filtrar="filtrar" @limparFiltro="getDisciplinas" />

    <tabela
      :items="paginacao.items"
      @editar="openModal"
      @excluir="confirmarExclusao"
    />

    <paginacao
      v-model="paginacao.paginacao"
      :total="paginacao.total"
      @input="getDisciplinas"
    />

    <modal-disciplina
      :form="modais.disciplinaDados"
      :exibir="modais.disciplina"
      @atualizar-tabela="getDisciplinas"
      @fechar="closeModal('disciplinas')"
    />
  </div>
</template>
<script>
// Utils & Aux:
import breadcrumb from '@/common/utils/breadcrumb';
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services & Models:
import Disciplina from '@/common/models/academico/Disciplina';
import DisciplinasService from '@/common/services/academico/disciplinas.service.js';

// Components:
import Filtro from './components/Filtro.vue';
import Titulo from './components/Titulo.vue';
import Tabela from './components/Tabela.vue';
import ModalDisciplina from './components/Modal.vue';
import Paginacao from '@/components/paginacao/Paginacao';

export default {
  components: {
    Filtro,
    Tabela,
    Titulo,
    ModalDisciplina,
    Paginacao,
  },
  data() {
    return {
      filtro: '',
      disciplinas: [],
      modais: {
        disciplina: false,
        disciplinaDados: new Disciplina({}),
      },
      paginacao: {
        paginacao: { numeroDaPagina: 1, tamanhoDaPagina: 10 },
        total: 0,
        items: [],
      },
    };
  },
  mounted() {
    breadcrumb.definir(this.$store, [
      { titulo: this.$t('ACADEMICO_HOME.PAINEL_ACADEMICO') },
      { titulo: this.$t('ACADEMICO.ESTRUTURA_CEJ') },
      { titulo: this.$t('ACADEMICO.DISCIPLINAS') },
    ]);
    this.getDisciplinas();
  },
  watch: {
    filtro() {
      return this.filtro;
    },
  },
  methods: {
    padronizarGeneroIdioma(data) {
      return data.map((data) => ({
        ...data,
        sexoDescricao: data.sexoId === 0 ? null : data.sexoDescricao,
        idiomaDescricao: data.idiomaId === 0 ? null : data.idiomaDescricao,
      }));
    },
    // FUNÇÕES DE OBTENÇÃO DE DADOS:
    getDisciplinas(filtroUtilizado) {
      const checandoFiltro =
        filtroUtilizado?.length !== undefined ? filtroUtilizado : '';

      this.$store.dispatch(START_LOADING);
      DisciplinasService.obterPorFiltro(
        this.paginacao.paginacao,
        checandoFiltro
      )
        .then(({ data }) => {
          this.disciplinas = this.padronizarGeneroIdioma(data.itens);
          this.paginacao.items = this.padronizarGeneroIdioma(data.itens);
          this.paginacao.total = data.paginacao.totalDeElementos;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES FILTRO:
    filtrar(filtroUtilizado) {
      if (filtroUtilizado.length) {
        this.getDisciplinas(filtroUtilizado);
      }
    },
    // FUNÇÕES CRUD:
    async confirmarExclusao(item) {
      const respostaExclusao = await mensagem.confirmacao(
        null,
        this.$t('ACADEMICO.CONFIRMAR_EXCLUIR_DISCIPLINA')
      );
      if (respostaExclusao.isConfirmed) {
        this.excluirDisciplina(item);
      }
    },
    excluirDisciplina(item) {
      this.$store.dispatch(START_LOADING);
      DisciplinasService.excluir(item.id)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('ACADEMICO.DISCIPLINA_EXCLUIDA')
          );
          this.getDisciplinas();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES MODAL:
    openModal(key, item) {
      item
        ? (this.modais[`${key}Dados`] = JSON.parse(JSON.stringify(item)))
        : (this.modais[`${key}Dados`] = new Disciplina({}));
      this.modais[key] = true;
    },
    closeModal() {
      this.modais.disciplina = false;
    },
  },
};
</script>
