<template>
  <topbar
    :titulo="$t('ACADEMICO.DISCIPLINAS')"
    :subtitulo="$t('ACADEMICO.DISCIPLINAS_CEJ_DESCRICAO')"
    :breadcrumbs="breadcrumbs"
  >
    <template #header>
      <div class="d-flex justify-content-between ml-md-4">
        <div />
        <b-button variant="info" class="mr-2" @click="cadastrar">
          {{ $t('ACADEMICO.DISCIPLINA_CADASTRAR') }}
        </b-button>
      </div>
    </template>
  </topbar>
</template>
<script>
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';

export default {
  components: { Topbar },
  data() {
    return {
      breadcrumbs: [
        { titulo: this.$t('ACADEMICO_HOME.PAINEL_ACADEMICO') },
        { titulo: this.$t('ACADEMICO.ESTRUTURA_CEJ') },
        { titulo: this.$t('ACADEMICO.DISCIPLINAS') },
      ],
    };
  },
  methods: {
    cadastrar() {
      this.$emit('cadastrar', 'disciplina');
    },
  },
};
</script>
